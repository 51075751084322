const linkList = {
  // 账号注册
  1: 'https://help.jclps.com/commonProblemController/problemContent.do?id=357&columnId=202&siteId=4',
  // 实名认证
  2: 'https://help.jclps.com/commonProblemController/problemContent.do?id=358&columnId=203&siteId=4',
  // 用户协议
  3: 'https://help.jclps.com/commonProblemController/problemContent.do?id=366&columnId=209&siteId=4',
  // 关于我们
  4: 'https://help.jclps.com/commonProblemController/problemContent.do?id=347&columnId=194&siteId=4',
}

export default linkList;
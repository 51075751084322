import linkList from './jumpList'

function registration() {
  pageJump(linkList[1])
}
function pageJump(params) {
  window.open(params)
}
export  {
  registration
}